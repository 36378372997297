<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-left">
                <div class="hzycenter-text">
                    <p></p>
                    <span>海外资源</span>
                </div> 
                <div class="nav-list">
                    <div class="nav-item" v-for="(item,index) in liebiaoList" :key="index" @click="goTab(item.id,item.pla_name)"> 
                        <div :class="{active:fl_id==item.id}">{{item.name}}</div>
                        <p><i class="el-icon-arrow-right"></i></p>                         
                    </div>
                </div>
            </div>

            <div class="conter-right">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: '/hwzy'}">海外资源</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: `/ziyuan_list?p_id=${p_id}&id=${gj_id}`}">{{name}}</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: `/ziyuan_list?p_id=${p_id}&id=${gj_id}`}">{{hzyname}}</el-breadcrumb-item>
                        <el-breadcrumb-item>{{fenleiname}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="details-text">{{detailsList.name}}</div>
                    <div class="details-list" v-html="detailsList.content"></div>
                    <div class="hzybutton" @click="goUrl(detailsList.link)" v-if="detailsList.link ">
                        <img src="../../assets/images/button.png" alt="">
                        <p>点此进入平台</p>
                    </div>

                    <!-- 海外仓 -->
                    <div class="anniu-wrap" v-if="id==243">
                        <div class="anniu-p">表单下载信息：</div>
                        <div class="anniu">
                            <div class="button" @click="goTo('haiwai_join',{coun_id:detailsList.coun_id,id:detailsList.id})">
                                <img src="../../assets/images/button.png" alt="">
                                <p>海外仓加盟</p>
                            </div>
                            <el-upload
                                class="button"     
                                name="file"               
                                :http-request="uploadFile" 
                                action="/" 
                                :limit="1"
                            >
                                <div class="">
                                    <img src="../../assets/images/button.png" alt="">
                                    <p>上传入仓申请<i class="el-icon-upload2"></i></p>
                                </div>                       
                            </el-upload>

                            <div class="button" v-for="(item,index) in detailsList.dws" :key="index" @click="goUrl(item.link_url)">
                                <img src="../../assets/images/button.png" alt="">
                                <p>{{item.title}}<i class="el-icon-download"></i></p>
                            </div>
                        </div>
                    </div>
                    <!-- 供求对接 -->
                    <div class="anniu-wrap" v-if="id==246">
                        <div class="anniu-p">表单信息：</div>
                        <div class="anniu">
                            <div class="button" @click="goTo('supply_join')">
                                <img src="../../assets/images/button.png" alt="">
                                <p>发布供求易货信息</p>
                            </div> 
                        </div>
                    </div>

                    <!-- 展会信息 -->
                    <div class="lianjie" v-if="id==247" >行业展会链接：<span  @click="goUrl(detailsList.link)">{{detailsList.link}}</span></div>


                    
                </div>

            </div>
        </div>
        
        
    </div>
</template>

<script>
    import CONFIG from "../../utils/config.js"
    import {mapState, mapMutations, mapActions} from 'vuex'  
    import axios from 'axios'
    import { getdetails ,getcountrys,getplatexts} from "../../api/index"
    export default {
        name:'state_list',
        data() {
            return {
                baseUrl:CONFIG.platextApplys,
                token:window.localStorage.getItem('token'),

                id:'',
                p_id:'',
                gj_id:'',
                hzyname:'',
                name:'',
                liebiaoList:[],
                fl_id:'',
                fenleiname:'',
                detailsList:[]
            }
        },
        computed: {
            ...mapState(['isLogin'])         
        },
        created() {
            this.id=this.$route.query.id
            this.p_id=this.$route.query.p_id
            this.gj_id=this.$route.query.gj_id
            this.getplatexts()

            this.hzycountrys()
            this.getcountrys()
            
        },
        methods: {   
            uploadFile(param) { // 文件提交
                if ( !this.isLogin) {                    
                    this.$confirm('请您先登录注册会员','温馨提示', {
                        confirmButtonText: '去登录',
                        }).then(() => {
                            this.$router.push({
                                path:'/login'
                            })
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '请登录后再操作'
                            });
                    });
                    return;
                }
                const formData = new FormData()
                formData.append('file', param.file) // 要提交给后台的文件,并且字段的key为Filedata
                // console.log(formData.get('file'));
                formData.append('user_id', 1)
                formData.append('platext_id', this.id)
                formData.append('api_token', this.token)
                let config = { // 添加请求头
                    headers: { 'Content-Type': 'multipart/form-data' }
                };                
                axios.post(this.baseUrl, formData, config)
                .then(res => {
                    console.log(res);
                    if (res.data.code==200) {
                        // console.log("成功");
                         this.$message.success(res.data.message)
                    }
                })
            },
            
            goUrl(url){
                window.open(url)
            }, 
            goTab(id,name){
                this.fl_id = id;
                this.fenleiname =name
                this.getdetails()
            },
            getplatexts(){
                getplatexts({
                    plate_id:this.id,
                    coun_id:this.gj_id,
                }).then((res)=>{
                    console.log(res);
                    if (res.code==200) {
                        this.liebiaoList=res.data.data
                        if (res.data.data.length>0) {
                            this.fl_id = res.data.data[0].id;
                            this.fenleiname = res.data.data[0].pla_name
                            this.getdetails()
                        }
                    }
                })
            },
            // 跳转页面
            goTo(url,query) {
                if ( !this.isLogin) {                    
                    this.$confirm('请您先登录注册会员','温馨提示', {
                        confirmButtonText: '去登录',
                        }).then(() => {
                            this.$router.push({
                                path:'/login'
                            })
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '请登录后再操作'
                            });
                    });
                    return;
                }
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            // 获取详情
            getdetails(){
                getdetails({
                    coun_id:this.gj_id,
                    id:this.fl_id
                }).then((res)=>{
                    console.log(res)
                    if (res.code==200) {
                        this.detailsList=res.data
                    }
                })
            },
            // 获取国家上级地区
            hzycountrys(){
                getcountrys().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        for (let index = 0; index < res.data.length; index++) {
                            if (this.p_id==res.data[index].id) {
                                this.name=res.data[index].name
                            }
                        }
                    }
                })
            },
            // 获取国家
            getcountrys(){
                getcountrys({
                    p_id:this.p_id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        for (let index = 0; index < res.data.length; index++) {
                            if (this.gj_id==res.data[index].id) {
                                this.hzyname=res.data[index].name
                            }
                        }
                        
                    }

                })
            },     
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;min-height: calc(100vh - 425px);}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}

            }
        }
        .conter-right{
            flex: 1;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 50px 20px;
                .details-text{color: #555555;font-size: 28px;text-align: center;padding-bottom: 20px;}
                
                .details-list{
                    margin:0 60px;
                    // display: flex;
                    // flex-wrap: wrap;                   

                }
                .lianjie{
                    color: #FE6D00;font-size: 18px;padding-top:15px;
                    span{text-decoration: underline;cursor: pointer;}
                }
                .hzybutton{
                    width: 151px;height: 43px;position: relative;margin:80px auto;cursor: pointer;
                    img{width: 100%;height: 100%;}
                    p{width: 151px;height: 43px;position: absolute;top: 0;left: 0;color: #0059AC;font-size: 18px;line-height: 43px;text-align: center;}
                }
                .anniu-wrap{
                    padding-top: 40px;
                    .anniu-p{
                        color:#555555;
                        font-size: 22px;
                    }
                    .anniu{
                        display: flex;flex-wrap: wrap;
                        .button{
                            width: 150px;height: 43px;position: relative;cursor: pointer;margin-right:2%;margin-top:30px;
                            img{width: 100%;height: 100%;}
                            p{
                                width: 100%;height: 100%;position: absolute;top: 0;left: 0;color: #0059AC;font-size: 18px;
                                display: flex;align-items:center;justify-content: center;
                                i{font-size: 25px;padding-top: 3px;}
                            }
                            &:nth-child(6n+0){margin-right: 0;}
                        } 

                    }
                }
            }
        }
        
    }

</style>